<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-stock-category-list'"
        :title="'Edit material category'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-form
        ref="form"
        lazy-validation
        class="ma-0 pt-6"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="stockItemCategory.name"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Name')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <StandardCardHeader
        :go-to-link="'admin-stock-category-list'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Data successfully saved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCellphoneKey,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

import axiosIns from '@axios'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const snackbarMessage = ''
    const stockItemCategoryId = null
    const snackbar = false
    const loading = true
    const options = {}
    const stockItemCategory = {
      id: '',
      name: '',
    }

    return {
      snackbarMessage,
      stockItemCategoryId,
      snackbar,
      options,
      loading,
      stockItemCategory,

      icons: {
        mdiCellphoneKey,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEmailAlert,
      },

      validator: {
        emailValidator,
        passwordValidator,
        required,
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      axiosIns.get(`/admin/stock-categories/${this.$route.params.id}`)
        .then(res => {
          const { stockItemCategory } = res.data
          this.stockItemCategoryId = stockItemCategory.id
          this.stockItemCategory.name = stockItemCategory.name
        })
        .catch()
    },
    save() {
      if (this.$refs.form.validate()) {
        const stockItemCategory = {
          name: this.stockItemCategory.name,
          address: this.stockItemCategory.address,
        }

        axiosIns({
          method: this.stockItemCategoryId ? 'PUT' : 'POST',
          data: stockItemCategory,
          url: this.stockItemCategoryId ? `/admin/stock-categories/${this.stockItemCategoryId}` : '/admin/stock-categories',
        })
          .then(res => {
            this.snackbarMessage = this.$t('Data successfully saved')
            this.snackbar = true
            this.stockItemCategoryId = res.data.stock.id
          })
          .catch(() => {
            this.snackbarMessage = this.$t('An error occurred')
            this.snackbar = true
          })
      }
    },
  },
}
</script>
